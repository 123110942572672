<template>

  <div class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="header-text">Распределение запчастей</h4>
        <span style="margin-right: 10px; font-size: 17px ;"><b-icon  icon="x-lg" type="submit" @click="$emit('showModal')"></b-icon></span>
      </div>
      <div class="modal-body">
        <label for="category" class="label_inputs">Назначение*</label><br>
        <b-form-select
            id="category"
            v-model="productData.appointment"
            class="modal__select"
            :options="listTransport"
            text-field="name"
            value-field="id"
        />
        <label for="quantity" class="label_inputs">Количество*</label><br>
        <b-form-input
            id="quantity"
            v-model="productData.quantity"
            type="number"
            placeholder="Введите количество"
            class="modal__input"
        />
      </div>
      <div class="modal-footer">
        <button class="default-btn btn-modal-cancel" @click="$emit('showModal')">
          Отменить
        </button>
        <button type="submit" @click="saveProduct()" class="default-btn btn-modal-save">
          Распределить
        </button>
        <button class="default-btn btn-modal-delete" @click="writeOff()">
          Списать товар
        </button>
      </div>
    </div>
  </div>


</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    product: Object
  },
  computed: {
    ...mapState([
      "transportList"
    ]),
    listTransport() {
      let transports = [{id: 0, 'name': "На склад"}];
      if (this.transportList) {
        this.transportList.forEach(e => {
          transports.push({id: e.id, name: e.model?.name + '|' + e.number + '|' + e.body})
        })
        return transports
      }
      return transports;
    },
    productData() {
      return {
        appointment: this.product.appointment,
        quantity: this.product.quantity
      }
    }
  },
  async created() {
    this.$store.dispatch("getTransportList");
  },
  methods: {
    saveProduct() {
      this.$api
          .post('/web/product/' + this.product.id, this.productData)
          .then(() => {
            this.$emit('search');
            this.$emit('showModal');
            this.$toast.success("Успешно");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            this.$emit('showModal');
          });
    },
    writeOff() {
      this.$api
          .post('/web/product/write-off/' + this.product.id, this.productData)
          .then(() => {
            this.$emit('search');
            this.$emit('showModal');
            this.$toast.success("Успешно");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            this.$emit('showModal');
          });
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.header-text {
  margin-left: 10px;
  margin-top: 10px;
}
.modal-body {
  padding: 1rem;
}
.label_inputs {
  font-weight: 400;
  display: flex;
}
.modal__select {
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #707070;
  width: 500px;
}
.modal__input {
  width: 500px;
  border-radius: 10px;
  color: #ACACAC;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.modal-footer {
  border-top: none;
  margin-top: 10px;
}
.btn-modal-cancel {
  width: 48%;
  background: #F2F2F8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.btn-modal-save {
  width: 48%;
  background: #E0E9FA;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366D6;
}
.btn-modal-delete {
  width: 100%;
  background: #FCE4E2;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #E74C3C;
}
</style>